<template>
<div class="deliver-material">
  <div class="card">
    <div class="common-title">
      <span>{{ $t('意愿确认') }}</span>
    </div>
    <van-radio-group v-model="insureType" direction="horizontal" class="radio-group">
      <van-radio v-for="(item,index) in insureTypeList" :key="index" :name="item.code">{{ item.name }}</van-radio>
    </van-radio-group>
  </div>
  <div class="common-footer">
    <van-button class="btn back" native-type="button" @click.stop="goBack">{{ $t('返回') }}</van-button>
    <van-button class="btn submit" type="submit" @click="onSubmit">
      {{ taskStatus === '4004002' ? $t('提交') : $t('更新') }}
    </van-button>
  </div>
</div>
</template>
<script>
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      taskStatus: '',
      insureType: 0,
      params: {
        id: '',
        taskStatus: '4004003',
        executionJson: {
          taskCode: 'BRAND_INSURANCE_PURCHASE_REMIND',
          insureType: 0
        },
      },
    }
  },
  computed: {
    ...mapGetters(['dictHash']),
    insureTypeList() {
      const arr = this.dictHash && (this.dictHash[4010] || [])
      return arr
    }
  },
  mounted() {
    const { taskId, taskStatus } = this.$route.query
    this.params.id = taskId
    this.taskStatus = taskStatus
    if (taskStatus === '4004003') {
      this.getTaskDetail()
    }
  },
  methods: {
    // 获取任务详情
    async getTaskDetail() {
      const params = {
        taskId: this.params.id,
      }
      const res = await deliveryServices.deliveryTaskDetail(params)
      // 回显数据
      this.insureType = res.executionJson.insureType
      this.params.executionJson.insureType = this.insureType

    },
    goBack() {
      this.$router.go(-1)
    },
    async onSubmit() {
      if (this.insureType === 0) {
        this.$toast.fail(`${this.$t('请选择')}`)
        return
      }
      this.params.executionJson.insureType = this.insureType
      loading.showLoading()
      const res = await deliveryServices.deliveryExecution(this.params)
      loading.hideLoading()
      if (res.code === 0) {
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      } else {
        this.$toast(res.msg)
      }
    }
  }

}
</script>
<style lang="less" scoped>
.deliver-material {
  padding: 16px;
  padding-bottom: 96px;

  .card {
    padding: 0 12px 4px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-bottom: 10px;

    .sub-title {
      padding: 12px 0;
    }
  }
}

.radio-group {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>